import React from 'react';

interface IconDetailProps {
    color?: string;
}

export function IconDetail(props: IconDetailProps): React.ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="50 50 400 400"><path d="M64 256c0 106 86 192 192 192s192-86 192-192S362 64 256 64 64 150 64 256z" fill="none" stroke={props.color || 'var(--primary-color)'} stroke-miterlimit="10" stroke-width="50"/><path fill="none" stroke={props.color || 'var(--primary-color)'} stroke-linecap="round" stroke-linejoin="round" stroke-width="50" d="M216 352l96-96-96-96"/></svg>
    );
}
